import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Wrapper from '../components/wrapper';

const HobbyPage = () => (
  <Layout>
    <SEO title="Hobbyist Board Gamer, Homebrewer, and Maker" />
    <Wrapper>
      <p>
        I collected a number of hobbies that come and go in phases. I love to
        experiment and create things. I like to incorporate my programming
        skills into whatever hobby I'm focusing on at the moment.
      </p>

      <p>
        My family and I enjoy playing board games and we've been slowly building
        a game collection. Related to gaming, I enjoy painting the miniatures
        from our board games and you can find examples of my work on{' '}
        <a href="https://www.instagram.com/drohyes/">Instagram</a>. I've also
        designed and build a number of inserts for our games in foam core and
        more recently with a 3D printer. I'm slowly uploading those designs to
        my <a href="https://www.thingiverse.com/drohyes/designs">Thingiverse</a>{' '}
        account.
      </p>

      <p>
        In the past I did a lot of homebrewing.{' '}
        <a href="http://manfre.net/">Michael Manfre</a> and I built and run{' '}
        <a href="https://brewedbyus.com/">Brewed By Us</a>, a homebrewing recipe
        collection website. I'm best known for my{' '}
        <a href="https://brewedbyus.com/recipes/136-holiday-chocolate-peppermint-stout/">
          Chocolate Peppermint Stout
        </a>{' '}
        which I used to make each year during the holidays. Most of the beers
        I've made can be found on{' '}
        <a href="https://brewedbyus.com/accounts/2-mlavin/">my profile</a>.
      </p>
    </Wrapper>
  </Layout>
);

export default HobbyPage;
